import React, { useState, useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { HiOutlineMenuAlt2, HiOutlineX } from "react-icons/hi";

import base from '../assets/images/header_base.png';
import base_mobile from '../assets/images/header_base_mobile.png';

import header from '../assets/images/header.png';
import header_en from '../assets/images/header_en.png';
import header_mobile from '../assets/images/header_mobile.png';
import header_mobile_en from '../assets/images/header_mobile_en.png';

import br from '../assets/images/br.png';
import en from '../assets/images/en.png';

import LanguageContext from '../contexts/language';
import AuthContext from '../contexts/auth';

export default function Header() {

    const { signed } = useContext(AuthContext);
    const { language, setLanguageStore } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    function setLanguage(lang) {
        setLanguageStore(lang);
    }

    const [menu, setMenu] = useState('close');

    return (
        <div className="header-menu">
            <div className="header">
                <Link to="/">
                    <img src={base} className="bg-cover header-desktop" style={{backgroundImage: `url(${lang==='pt' ? header : header_en})`}} />
                    <img src={base_mobile} className="bg-cover header-mobile" style={{backgroundImage: `url(${lang==='pt' ? header_mobile : header_mobile_en})`}} />
                </Link>
            </div>
            <Navbar>
                <Container className="flex-row">
                    <div className="menu-mobile" onClick={() => setMenu('open')}>
                        <HiOutlineMenuAlt2 />
                        <NavLink to="/salas" className="nav-link nav-login">{language.menu_watch}</NavLink>
                    </div>
                    <div className="menu-content-desktop">
                        <Navbar.Collapse>
                            <Nav>
                                <NavLink to="/home" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_home}</NavLink>
                                <div className="has-submenu">
                                    <NavLink to="/agenda" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_agenda}</NavLink>
                                    <div className="submenu">
                                        <NavLink to="/minha-agenda" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_my_agenda}</NavLink>
                                    </div>
                                </div>

                                {/* <NavLink to="/inscricao" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_inscricao}</NavLink> */}
                                <NavLink to="/imprensa" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_inscricao_imprensa}</NavLink>
                                <NavLink to="/novidades" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_news}</NavLink>
                                <NavLink to="/salas" className="nav-link nav-login">{language.menu_watch}</NavLink>
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                    <NavDropdown title={<>
                            <img src={lang==='pt' ? br : en} />
                            <span>{language.language_active }</span>
                        </>} menuVariant="dark">
                        <NavDropdown.Item onClick={() => {setLanguage('pt')}}>{language.language_pt_inverse}</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => {setLanguage('en')}}>{language.language_en_inverse}</NavDropdown.Item>
                    </NavDropdown>
                </Container>
            </Navbar>
            <div className={`menu-content ${menu}`}>
                <div className="menu-mobile-close" onClick={() => setMenu('close')}>
                    <HiOutlineX />
                </div>
                <Navbar>
                    <Nav>
                        <NavLink to="/home" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_home}</NavLink>
                        <NavLink to="/agenda" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_agenda}</NavLink>
                        <NavLink to="/minha-agenda" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_my_agenda}</NavLink>
                        {/* <NavLink to="/inscricao" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_inscricao}</NavLink> */}
                        <NavLink to="/imprensa" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_inscricao_imprensa}</NavLink>
                        <NavLink to="/novidades" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_news}</NavLink>
                        <NavLink to="/salas" className="nav-link nav-login">{language.menu_watch}</NavLink>
                    </Nav>
                </Navbar>
            </div>
        </div>
    );
}