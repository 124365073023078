import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LuCalendarDays } from "react-icons/lu";

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LanguageContext from '../../contexts/language';

export default function Agenda() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    const [events, setEvents] = useState([]);

    async function loadEvents(){
        setLoading(true);
        try{
            const response = await api.post(`/events`);
            setEvents(response.data.content.events);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        loadEvents();
    }, []);

    return (
        <div className="page">
            <Header />
            <div className="content">
                <div className="full mt-80 mb-40 text-center form">
                    <h3 className="title_b mb-60">
                        {language.menu_agenda}
                    </h3>
                    <div className={`all-events mt-10`}>
                        {events.map(item => (
                        <div key={`events-${item.id}`} className="event-box event-box-big">
                            <div className="event-container">
                                <div className="event-title">
                                    <LuCalendarDays />
                                    <span>{lang==='pt' ? item.date : item.date_en}</span>
                                </div>
                                <div className="event-date">{lang==='pt' ? item.title : item.title_en}</div>
                            </div>
                            {item.event.map(event => (
                            <div key={`event-${event.id}`} className={`event-content dont-click`}>
                                <div className={`event-hour ${lang}`}>
                                    <span>{lang==='pt' ? event.time_start : event.time_start_en} - {lang==='pt' ? event.time_end : event.time_end_en}</span>
                                </div>
                                <div className="event-content-wrapper">
                                    <div className="event-name">{lang==='pt' ? event.title : event.title_en}</div>
                                    <div className="event-description" dangerouslySetInnerHTML={{__html: lang==='pt' ? event.description : event.description_en}} />
                                </div>
                            </div>))}
                        </div>))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
