import React, { useState, useEffect, useRef, useContext } from 'react';
import { LuCalendarDays } from "react-icons/lu";
import { FaRegSquare, FaSquareCheck, FaRegCircle, FaRegCircleDot } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LanguageContext from '../../contexts/language';
import AuthContext from '../../contexts/auth';

export default function InscricaoUpdate() {

    const { signed } = useContext(AuthContext);
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [success_message, setSuccessMessage] = useState('');
    const [check_all, setCheckAll] = useState(false);

    const [original_events, setOriginalEvents] = useState([]);
    const [events, setEvents] = useState([]);

    const [id, setId] = useState('');
    const [user_type, setUserType] = useState('user');
    const [type, setType] = useState('online');

    async function handleEventPress(eventId, eventStart, eventEnd) {
        setEvents(prevState => prevState.map(eventItem => {
            const updatedEvents = eventItem.event.map(event => {
                if (event.id === eventId) {
                    return { ...event, checked: !event.checked };
                }
                return event;
            });
            return { ...eventItem, event: updatedEvents };
        }));
    }

    async function handleEventChecked(e) {
        setEvents(prevState => prevState.map(eventItem => {
            const updatedEvents = eventItem.event.map(event => {
                setCheckAll(!check_all);
                return { ...event, checked: !event.disabled && !check_all };
            });
            return { ...eventItem, event: updatedEvents };
        }));
    }

    function clearMessage(){
        setError(false);
        setErrorMessage('');
        setSuccess(false);
        setSuccessMessage('');
    }

    function clearState(){
        setEvents(original_events);
        setUserType('user');
        setId('');
        setType('online');
        setCheckAll(false);
    }

    async function sendRegister(e){
        e.preventDefault();
        setLoading(true);
        setSuccess(false);
        setSuccessMessage("");

        if(id===""){
            setLoading(false);
            setError(true);
            setErrorMessage('Coloque o Código!');
            setSuccess(false);
            setSuccessMessage('');
            return;
        }
        try{
            const response = await api.post(`/update`, {
                id,
                type_user: user_type,
                type_subscribe: type,
                events
            });

            if(response.data.status==="success"){
                setLoading(false);
                setError(false);
                setErrorMessage('');
                setSuccess(true);
                setSuccessMessage(language.success);
                clearState();
            }else{
                setLoading(false);
                setError(true);
                setErrorMessage(language.error_found);
                setSuccess(false);
                setSuccessMessage('');
            }
            
        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(err.message);
            setSuccess(false);
            setSuccessMessage("");
        }
    }

    async function loadPage(){
        setLoading(true);
        try{
            const response = await api.post(`/events?calendar=yes&avaliable=yes`);
            setEvents(response.data.content.events);
            setOriginalEvents(response.data.content.events);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }
    
    useEffect(() => {
        loadPage();
    }, [signed]);

    return (
        <div className="page">
            <Header />
            <div className="content">
                <div className="full mt-80 mb-40 text-center">
                    <form onSubmit={sendRegister} className={`form ${ loading ? 'loading' : '' }`}>
                        <h3 className="title_b mb-60">
                            {language.menu_inscricao}
                        </h3>
                        <div className="d-input mt-20">
                            <label>{language.code}:</label>
                            <input type="text" value={id} onChange={e => setId(e.target.value)} />
                        </div>
                        <div className="d-input">
                            <label>Categoria:</label>
                            <div className="d-check">
                                <div className={`d-check-label ${user_type==='user' ? 'checked' : ''}`} onClick={() => {setUserType('user');clearMessage();}}>
                                    {user_type==='user' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>Convidado</span>
                                </div>
                                <div className={`d-check-label ${user_type==='press' ? 'checked' : ''}`} onClick={() => {setUserType('press');clearMessage();}}>
                                    {user_type==='press' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>Imprensa</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-input">
                            <label>{language.type}:</label>
                            <div className="d-check">
                                <div className={`d-check-label ${type==='presencial' ? 'checked' : ''}`} onClick={() => {setType('presencial');clearMessage();}}>
                                    {type==='presencial' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.presencial}</span>
                                </div>
                                <div className={`d-check-label ${type==='online' ? 'checked' : ''}`} onClick={() => {setType('online');clearMessage();}}>
                                    {type==='online' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.online}</span>
                                </div>
                            </div>
                        </div>

                        <div className={`all-events mt-40 ${type==='presencial' ? '' : 'd-none'}`}>
                            <div className="event-text">{language.select}:</div>
                            <div className={`event-content event-subscribe ${check_all ? 'checked' : ''}`} onClick={() => handleEventChecked()}>
                                <div className={`event-hour check-all`}>
                                    {check_all ?
                                    <FaSquareCheck />:
                                    <FaRegSquare />}
                                    <span>{lang==='pt' ? 'Selecionar Todos' : 'Check All'}</span>
                                </div>
                            </div>
                            {events.map(item => (
                            <div key={`events-${item.id}`} className="event-box">
                                <div className="event-container">
                                    <div className="event-title">
                                        <LuCalendarDays />
                                        <span>{lang==='pt' ? item.date : item.date_en}</span>
                                    </div>
                                    <div className="event-date">{lang==='pt' ? item.title : item.title_en}</div>
                                </div>
                                {item.event.map(event => (
                                <div key={`event-${event.id}`} className={`event-content event-subscribe ${event.checked ? 'checked' : ''}  ${event.disabled ? 'disabled' : ''} ${event.force_disabled ? 'disabled' : ''}`} onClick={() => {
                                    handleEventPress(
                                        event.id,
                                        event.date_start,
                                        event.date_end
                                    );
                                }}>
                                    <div className={`event-hour ${lang}`}>
                                        {event.checked ?
                                        <FaSquareCheck />:
                                        <FaRegSquare />}
                                        <span>{lang==='pt' ? event.time_start : event.time_start_en} - {lang==='pt' ? event.time_end : event.time_end_en}</span>
                                    </div>
                                    <div className="event-name">{lang==='pt' ? event.title : event.title_en}</div>
                                </div>))}
                            </div>))}
                        </div>

                        {success && <div className="success space mt-20 mb-20">{success_message}</div>}
                        {error && <div className="error space mt-20 mb-20">{error_message}</div>}

                        <div className="d-btn mt-30">
                            <button type="submit" className="btn-send">{language.update}</button>
                        </div>
                    </form>

                </div>
            </div>
            <Footer />
        </div>
    );
}
