import React, { useState, useEffect, useRef, useContext } from 'react';
import { LuCalendarDays } from "react-icons/lu";
import { FaRegSquare, FaSquareCheck, FaRegCircle, FaRegCircleDot } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LanguageContext from '../../contexts/language';
import AuthContext from '../../contexts/auth';

export default function Imprensa() {

    const { signed } = useContext(AuthContext);
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [link, setLink] = useState([]);

    async function loadPage(){
        setLoading(true);
        try{
            const response = await api.post(`/events?calendar=yes`);
            setLink(response.data.content.link);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }
    
    useEffect(() => {
        loadPage();
    }, []);

    return (
        <div className="page">
            <Header />
            <div className="content">
                <div className="full mt-80 mb-40 text-center form">
                    <h3 className="title_b mb-30">
                        {language.menu_inscricao_imprensa}
                    </h3>
                    {link!='' &&
                    <a target="_blank" href={lang==='pt' ? link.pt : link.en} className="home-link home-link-mini mb-30">
                        <span>Press Release</span>
                    </a>}
                </div>
            </div>
            <Footer />
        </div>
    );
}
