import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useParams, useNavigate } from 'react-router-dom';
import { LuCalendarDays, LuPower, LuArrowLeft } from "react-icons/lu";
import dayjs from 'dayjs';

import api from '../../services/api';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LanguageContext from '../../contexts/language';
import AuthContext from '../../contexts/auth';

export default function Salas() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const { signed, user, token, signOut } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    let interval;
    let interval_video;
    
    const { url } = useParams();

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const refVideo = useRef();
    const refComment = useRef();
    const refComments = useRef();
    
    const [events, setEvents] = useState([]);
    const [event, setEvent] = useState([]);
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('');

    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [success_message, setSuccessMessage] = useState('');
    const [current_video, setCurrentVideo] = useState('');

    function updateWidthAndHeight(){
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    });

    function getVideoHeight(){
        if(refVideo.current){
            return refVideo.current.clientHeight;
        }else{
            return 0;
        }
    }

    function scrollTop() {
        refComments.current.scrollTo(0, 0);
    }

    function writeComment(e){
        if(e.key === 'Enter'){
        	sendComment(e);
        }else{
        	return true;
        }
    }

    async function loadComments(){
        const response = await api.post(`/comments`, {
            url
        });
        setComments(response.data.content.comments ? response.data.content.comments : []);
        setLoading(false);
    }

    async function sendComment(e){
        e.preventDefault();
        setLoading(true);

        try{
            if(comment==""){
                setLoading(false);
                setError(true);
                setErrorMessage("Escreva um comentário!");
                clearMessage();
                return;
            }
            const response = await api.post(`/add-comment`, {
                url,
                comment
            }, config);

            setLoading(false);
            if(response.data.status==='success'){
                setComment('');
                setError(false);
                setErrorMessage("");
                setSuccess(true);
                setSuccessMessage("Comentário enviado com sucesso!");
                loadComments();
                clearMessage();
            }else{
                setError(true);
                setErrorMessage("Erro ao enviar comentário!");
                setSuccess(false);
                setSuccessMessage("");
                clearMessage();
            }

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage("Erro ao enviar comentário!");
            setSuccess(false);
            setSuccessMessage("");
            clearMessage();
        }
    }

    function clearMessage(){
        setTimeout(() => {
            setError(false);
            setErrorMessage("");
            setSuccess(false);
            setSuccessMessage("");
        }, 2000);
    }

    function firstName(str_name) {
        if (typeof str_name === 'string' && str_name.includes(" ")) {
            var partes = str_name.split(" ");
            return partes[0];
        } else {
            return str_name;
        }
    }

    async function logout() {
        signOut();
        navigate('/login');
    }

    async function loadEvents(){
        setLoading(true);
        try{
            const response = await api.post(`/events-user`);
            setEvents(response.data.content.events);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    async function loadEvent(){

        /* if(!signed){
            navigate(`/login?redirect=/sala/${url}`);
            return;
        } */
        setLoading(true);
        try{
            const response = await api.post(`/event`, {
                url
            });
            setEvent(response.data.content.events);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        if(url=='' || url==null){
            loadEvents();
            clearInterval(interval);
        }else{
            setEvent([]);
            loadEvent();
            //loadComments();
        }
    }, [url]);


    /* function getVideo(current_video, event, lang){
        const today = dayjs();
        const currentHour = today.hour();
        const currentMinute = today.minute();
        const targetHour = 14;
        const targetMinute = 30;
        const video_1 = lang==='pt' ? event.video : event.video_en
        const video_2 = lang==='pt' ? event.video_after : event.video_after_en
        if (currentHour > targetHour || (currentHour === targetHour && currentMinute >= targetMinute)) {
            if(current_video!==video_2){
                setCurrentVideo(video_2);
            }
        } else {
            if(current_video!==video_1){
                setCurrentVideo(video_1);
            }
        }
    } */

    return (
        <div className="page">
            <Header />
            {url=='' || url==null ?
            <>
                <div className="content">
                    <div className="full mt-80 mb-40 text-center form">
                        <h3 className="title_b mb-60">
                            {language.select_watch}
                        </h3>
                        <div className={`all-events mt-10`}>
                            {events.map(item => (
                            <div key={`events-${item.id}`} className="event-box event-box-big">
                                <div className="event-container">
                                    <div className="event-title">
                                        <LuCalendarDays />
                                        <span>{lang==='pt' ? item.date : item.date_en}</span>
                                    </div>
                                    <div className="event-date">{lang==='pt' ? item.title : item.title_en}</div>
                                </div>
                                {item.event.map(event => (
                                <div key={`event-${event.id}`} className={`event-content ${event.click ? '' : 'dont-click-event'}`} onClick={() => navigate(`/sala/${event.url}`)}>
                                    <div className={`event-hour pt-0 ${lang}`}>
                                        <span>{lang==='pt' ? event.time_start : event.time_start_en} - {lang==='pt' ? event.time_end : event.time_end_en}</span>
                                    </div>
                                    <div className="event-name">{lang==='pt' ? event.title : event.title_en}</div>
                                </div>))}
                            </div>))}
                        </div>
                    </div>
                </div>
                <Footer />
            </>:
            <>
                <div className="content-video">
                    <div className="full-video">
                        <div className="header-title">
                            <div className="container align-items-start m-0">
                                <a className="header_back" onClick={() => navigate('/salas')}>
                                    <LuArrowLeft size={20} />
                                    <div>{language.back}</div>
                                </a>
                            </div>
                        </div>
                        <div className="container-video-comments">
                            <div className="container-video">
                                <div ref={refVideo} className="embed-container">
                                    {event!='' &&
                                    <ReactPlayer
                                        url={`https://www.youtube.com/embed/${lang==='pt' ? event.video : event.video_en}?rel=0&showinfo=0&autoplay=1`}
                                        controls={true}
                                        playsinline={true}
                                        playing={true}
                                        frameBorder="0"
                                        allowFullScreen
                                    />}
                                </div>
                            </div>
                            {/* <div className="container-comments" style={{height:getVideoHeight()}}>
                                <div ref={refComments} className={`container-comments-wrapper`}>
                                    <div className="header-action">
                                        <div className="header_text">{language.hello}, <strong>{firstName(user?.name)}</strong></div>
                                        <a className="header_link" onClick={logout}>
                                            <LuPower size={18} />
                                            <div>{language.logout}</div>
                                        </a>
                                    </div>
                                    <div className="divider-comment"></div>
                                    <div>
                                        <div className="comments">
                                            {success && <div className="success mb-20">{success_message}</div>}
                                            {error && <div className="error mb-20">{error_message}</div>}
                                            {comments.length>0 ?
                                            <>
                                            {comments.map(item => (
                                            <>
                                                <div key={item.id} className="comment">
                                                    <span className="comment-name">{item.name}: </span>
                                                    <span className="comment-text" dangerouslySetInnerHTML={{__html: item.text}} />
                                                </div>
                                                <div className="divider-comment"></div>
                                            </>
                                            ))}
                                            </>:
                                            <div className="no-comment">{language.not_comment}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <form onSubmit={sendComment} className="form-container">
                                    <div className="form-container-wrapper">
                                        <div className={`l-input ${ loading ? 'loading' : '' }`}>
                                            <textarea ref={refComment} placeholder={language.write_comment} value={comment} onKeyPress={writeComment} onChange={e => setComment(e.target.value)} rows="2" />
                                        </div>
                                        <div className={`d-btn ${ loading ? 'loading' : '' }`}>
                                            <button type="submit" className="btn-send">{language.send_comment}</button>
                                        </div>
                                    </div>
                                </form>
                            </div> */}
                        </div>
                    </div>
                </div>
                <Footer noLogo />
            </>}
        </div>
    );
}
