import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import LanguageContext from '../../contexts/language';

export default function Home() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    return (
        <div className="page">
            <Header />
            <div className="mt-30 mb-30">
                <div className="container">
                    <div className="col-md-10 m-auto">
                        {/* {lang==='pt' ?
                        <div className="alert-text">
                            <p>Informamos que nosso evento superou a capacidade de público. Contamos com a compreensão de todos os inscritos para que cheguem com antecedência para credenciamento. Reforçamos que as salas estão sujeitas à lotação. Lembrando que todo o evento será transmitido também no online.</p>
                        </div>:
                        <div className="alert-text">
                            <p>We would like to inform you that our event has exceeded its audience capacity. We ask all those registered to arrive early for accreditation. Rooms are subject to capacity. Remember that the entire event will also be broadcasted.</p>
                        </div>} */}
                        {lang==='pt' ?
                        <div className="text">
                            <p>O Fórum, lançado na COP28 junto ao Ministro da Fazenda Fernando Haddad, antecede a reunião de Ministros das Finanças do G20 e reunirá integrantes do setor privado, da sociedade civil, da filantropia, de organismos multilaterais de financiamento, nacionais e internacionais, que estão trabalhando para impulsionar uma transição verde e justa em direção a uma economia global de baixo carbono.</p>
                            <p>A programação inclui sessões de trabalho, painéis e mesas-redondas na primeira de uma série de iniciativas a serem desenvolvidas conjuntamente pelo Instituto Arapyaú, Instituto AYA, Instituto Clima e Sociedade (iCS), Instituto Igarapé, Instituto Itaúsa, Open Society Foundations e Uma Concertação pela Amazônia ao longo da agenda do G20.</p>
                            <p>Este é um evento restrito a convidados e sujeito à lotação. Por isso, é necessária a inscrição prévia nos painéis de interesse.</p>
                            <p className="mt-20 small-text">Aviso de Tratamento de Dados Pessoais: nos termos da Lei Geral de Proteção de Dados Pessoais, Lei 13.709/2018 (“LGPD”) informamos que a Organização do evento "Fórum Brasileiro de Finanças Climáticas" tratará os dados pessoais cadastrais relativos a pessoas físicas, representantes e/ou associados da pessoa jurídica para os fins de  credenciamento para o evento.  A Colab208 produtora do evento garante que o tratamento será feito em observância às melhores práticas de proteção de dados pessoais, conforme as diretrizes e princípios éticos norteadores da LGPD e garantirá o exercício dos direitos salvaguardados por aquela lei.</p>
                        </div>:
                        <div className="text">
                            <p>The Forum, first announced at COP28 alongside Minister Fernando Haddad, preceeds the G20 Finance Ministers meeting and will bring together members of the private sector, business coalitions, civil society, philanthropy, multilateral financing and economic organizations, both national and international, that are driving a green and just transition to a global low-carbon economy.</p>
                            <p>The programme includes working sessions, panels and roundtables in the first of a series of initiatives developed jointly by Instituto Arapyaú, AYA Institute, Institute for Climate and Society (iCS), Igarapé Institute, Itaúsa Institute, Open Society Foundations and Amazon Concertation over the course of the G20 agenda.</p>
                            <p>This is a restricted event by invitation only, and may reach full capacity. Therefore, participants must sign up for the sessions they will attend.</p>
                            <p className="mt-20 small-text">Notice of Processing of Personal Data: under the terms of the General Law on Protection of Personal Data, Law 13.709/2018 ("LGPD"), we inform you that the Organization of the event "Brazilian Forum on Climate Finance" will process personal registration data relating to individuals, representatives and/or associates of the legal entity for the purposes of accreditation for the event.  Colab208, producer of the event, guarantees that the processing will be carried out in compliance with the best personal data protection practices, in accordance with the guidelines and guiding ethical principles of the LGPD and will guarantee the exercise of the rights safeguarded by that law.</p>
                        </div>}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
