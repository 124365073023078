import React, { useContext } from 'react';
import logo from '../assets/images/nerdetcetera.png';
import footer from '../assets/images/footer.jpg';
import footer_mobile from '../assets/images/footer_mobile.jpg';

import LanguageContext from '../contexts/language';

export default function Footer({noLogo}) {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    return (
        <div className="footer">
            {!noLogo &&
            <div className="footer-image">
                <img src={footer} className="footer-desktop" />
                <img src={footer_mobile} className="footer-mobile" />
            </div>}
            <div className="container mt-0 mb-0">
                <div className="footer-container">
                    <div className="footer-left">©2024 {language.site_name}</div>
                    <div className="footer-right">{language.desenvolvido} <a className="dev_link" target="_blank" href="https://nerdetcetera.com"><img src={logo} /></a></div>
                </div>
            </div>
        </div>
    );
}