import React, { useState, useEffect, useRef, useContext } from 'react';
import { LuCalendarDays } from "react-icons/lu";
import { FaRegSquare, FaSquareCheck, FaRegCircle, FaRegCircleDot } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LanguageContext from '../../contexts/language';
import AuthContext from '../../contexts/auth';

export default function InscricaoVip() {

    const { signed } = useContext(AuthContext);
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [success_message, setSuccessMessage] = useState('');
    const [check_all, setCheckAll] = useState(false);

    const [original_events, setOriginalEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [organization, setOrganization] = useState('');
    const [office, setOffice] = useState('');
    const [age, setAge] = useState('');
    const [race, setRace] = useState('');
    const [genre, setGenre] = useState('');
    const [phone, setPhone] = useState('');
    const [disability, setDisability] = useState('');
    const [food, setFood] = useState('');
    const [type, setType] = useState('');

    const nameInput = useRef(null);
    const emailInput = useRef(null);
    const passwordInput = useRef(null);
    const organizationInput = useRef(null);
    const officeInput = useRef(null);
    const ageInput = useRef(null);
    const raceInput = useRef(null);
    const genreInput = useRef(null);
    const disabilityInput = useRef(null);
    const foodInput = useRef(null);

    function handleAge(value) {
        const number = value.replace(/[^0-9]/g, '');
        setAge(number);
    }

    async function handleEventPress(eventId, eventStart, eventEnd) {
        setEvents(prevState => prevState.map(eventItem => {
            const updatedEvents = eventItem.event.map(event => {
                if (event.id === eventId) {
                    return { ...event, checked: !event.checked };
                }
                return event;
            });
            return { ...eventItem, event: updatedEvents };
        }));
    }

    async function handleEventChecked(e) {
        setEvents(prevState => prevState.map(eventItem => {
            const updatedEvents = eventItem.event.map(event => {
                setCheckAll(!check_all);
                return { ...event, checked: !event.disabled && !check_all };
            });
            return { ...eventItem, event: updatedEvents };
        }));
    }

    function eventChecked(events) {
        return events.some(eventItem => eventItem.event.some(event => event.checked));
    }

    function clearMessage(){
        setError(false);
        setErrorMessage('');
        setSuccess(false);
        setSuccessMessage('');
    }

    function clearState(){
        setEvents(original_events);
        setName('');
        setEmail('');
        setPassword('');
        setOrganization('');
        setOffice('');
        setAge('');
        setRace('');
        setGenre('');
        setPhone('');
        setType('');
    }

    async function sendRegister(e){
        e.preventDefault();
        setLoading(true);
        setSuccess(false);
        setSuccessMessage("");
        /*
        maria.netto@climaesociedade.org
        012045

        marisa@climaesociedade.org
        280466
        */
        
        if(name.trim() === ''){
            nameInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(email.trim() === ''){
            emailInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(password.trim() === ''){
            passwordInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(organization.trim() === ''){
            organizationInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(office.trim() === ''){
            officeInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(age.trim() === ''){
            ageInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(race.trim() === ''){
            raceInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(genre.trim() === ''){
            genreInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(type === ''){
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_type);
            return;
        }

        /* if(type==='presencial'){
            const is_checked = eventChecked(events);
            if(!is_checked){
                setLoading(false);
                setError(true);
                setErrorMessage(language.error_select);
                return;
            }
        } */

        try{
            const response = await api.post(`/register`, {
                lang,
                name,
                email,
                password,
                organization,
                office,
                age,
                race,
                genre,
                phone,
                type_subscribe: type,
                events
            });

            if(response.data.status==="success"){
                setLoading(false);
                setError(false);
                setErrorMessage('');
                setSuccess(true);
                setSuccessMessage(language.success);
                clearState();
            }else{
                setLoading(false);
                setError(true);
                setErrorMessage(language.error_email);
                setSuccess(false);
                setSuccessMessage('');
            }
            
        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(err.message);
            setSuccess(false);
            setSuccessMessage("");
        }
    }

    async function loadPage(){
        setLoading(true);
        try{
            const response = await api.post(`/events?calendar=yes&avaliable=yes`);
            setEvents(response.data.content.events);
            setOriginalEvents(response.data.content.events);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }
    
    useEffect(() => {
        /* if(signed){
            navigate('/salas');
            return;
        } */
        loadPage();
    }, [signed]);

    return (
        <div className="page">
            <Header />
            <div className="content">
                <div className="full mt-80 mb-40 text-center">
                    <form onSubmit={sendRegister} className={`form ${ loading ? 'loading' : '' }`}>
                        <h3 className="title_b mb-60">
                            {language.menu_inscricao}
                        </h3>
                        <div className="d-input mt-20">
                            <label>{language.name}: <span>*</span></label>
                            <input type="text" ref={nameInput} value={name} onChange={e => {setName(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.email}: <span>*</span></label>
                            <input type="email" ref={emailInput} value={email} onChange={e => {setEmail(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.password}: <span>*</span></label>
                            <input type="password" ref={passwordInput} value={password} onChange={e => {setPassword(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.organization}: <span>*</span></label>
                            <input type="text" ref={organizationInput} value={organization} onChange={e => {setOrganization(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.office}: <span>*</span></label>
                            <input type="text" ref={officeInput} value={office} onChange={e => {setOffice(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.age}: <span>*</span></label>
                            <input type="tel" ref={ageInput} value={age} onChange={e => {handleAge(e.target.value);clearMessage();}} maxLength={2} />
                        </div>
                        <div className="d-input">
                            <label>{language.race}: <span>*</span></label>
                            <input type="text" ref={raceInput} value={race} onChange={e => {setRace(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.genre}: <span>*</span></label>
                            <input type="text" ref={genreInput} value={genre} onChange={e => {setGenre(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.disability}:</label>
                            <input type="text" ref={disabilityInput} value={disability} onChange={e => {setDisability(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.food}:</label>
                            <input type="text" ref={foodInput} value={food} onChange={e => {setFood(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.phone}:</label>
                            <input type="text" value={phone} onChange={e => {setPhone(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.type}: <span>*</span></label>
                            <div className="d-check">
                                <div className={`d-check-label ${type==='presencial' ? 'checked' : ''}`} onClick={() => {setType('presencial');clearMessage();}}>
                                    {type==='presencial' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.presencial}</span>
                                </div>
                                <div className={`d-check-label ${type==='online' ? 'checked' : ''}`} onClick={() => {setType('online');clearMessage();}}>
                                    {type==='online' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.online}</span>
                                </div>
                            </div>
                        </div>

                        <div className={`all-events mt-40 ${type==='presencial' ? '' : 'd-none'}`}>
                            <div className="event-text">{language.select}:</div>
                            <div className={`event-content event-subscribe ${check_all ? 'checked' : ''}`} onClick={() => handleEventChecked()}>
                                <div className={`event-hour check-all`}>
                                    {check_all ?
                                    <FaSquareCheck />:
                                    <FaRegSquare />}
                                    <span>{lang==='pt' ? 'Selecionar Todos' : 'Check All'}</span>
                                </div>
                            </div>
                            {events.map(item => (
                            <div key={`events-${item.id}`} className="event-box">
                                <div className="event-container">
                                    <div className="event-title">
                                        <LuCalendarDays />
                                        <span>{lang==='pt' ? item.date : item.date_en}</span>
                                    </div>
                                    <div className="event-date">{lang==='pt' ? item.title : item.title_en}</div>
                                </div>
                                {item.event.map(event => (
                                <div key={`event-${event.id}`} className={`event-content event-subscribe ${event.checked ? 'checked' : ''}  ${event.disabled ? 'disabled' : ''} ${event.force_disabled ? 'disabled' : ''}`} onClick={() => {
                                    handleEventPress(
                                        event.id,
                                        event.date_start,
                                        event.date_end
                                    );
                                }}>
                                    <div className={`event-hour ${lang}`}>
                                        {event.checked ?
                                        <FaSquareCheck />:
                                        <FaRegSquare />}
                                        <span>{lang==='pt' ? event.time_start : event.time_start_en} - {lang==='pt' ? event.time_end : event.time_end_en}</span>
                                    </div>
                                    <div className="event-name">{lang==='pt' ? event.title : event.title_en}</div>
                                </div>))}
                            </div>))}
                        </div>

                        {success && <div className="success space mt-20 mb-20">{success_message}</div>}
                        {error && <div className="error space mt-20 mb-20">{error_message}</div>}

                        <div className="d-btn mt-30">
                            <button type="submit" className="btn-send">{language.send} {type==='presencial' ? language.pre_inscricao : language.inscricao}</button>
                        </div>
                    </form>

                </div>
            </div>
            <Footer />
        </div>
    );
}
