import React from 'react';
import { Link } from 'react-router-dom';

import agenda from '../../assets/images/agenda_en.png';
import br from '../../assets/images/br.png';
import en from '../../assets/images/en.png';

export default function PageEn() {

    return (
        <div className="page">
            <div className="content">
                <img src={agenda} />
            </div>
            <div className="home-action">
                <Link to="/br" className="home-link">
                    <img src={br} />
                    <span>Agenda preliminar</span>
                </Link>
                <Link to="/en" className="home-link">
                    <img src={en} />
                    <span>Preliminary agenda</span>
                </Link>
            </div>
        </div>
    );
}
