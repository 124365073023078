import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { LuCalendarDays } from "react-icons/lu";
import Card from 'react-bootstrap/Card';

import api from '../../services/api';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loader from '../../components/Loader';
import Shimmer from '../../components/Shimmer';

import LanguageContext from '../../contexts/language';

import Base from '../../assets/images/base-square.png';

export default function News() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    const { url } = useParams();

    const [news, setNews] = useState([]);
    const [items, setItems] = useState([]);

    async function loadNews(){
        setLoading(true);
        try{
            const response = await api.post(`/news`);
            setNews(response.data.content.news);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    async function loadNew(){

        setLoading(true);
        try{
            const response = await api.post(`/post?url=${url}`);
            setNews(response.data.content.news[0]);
            setItems(response.data.content.news[0].post_items ? response.data.content.news[0].post_items : []);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        if(url=='' || url==null){
            loadNews();
        }else{
            loadNew();
        }
    }, [url]);

    return (
        <div className="page">
            <Header />
            {loading ?
            <Loader />:
            <>
                {url=='' || url==null ?
                <div className="full mt-80 mb-40 form">
                    <h3 className="title_b">
                        {language.menu_news}
                    </h3>
                    {Array.isArray(news) &&
                    <div className="container">
                        <div className="row d-flex justify-content-center gh-4 gv-4 news">
                            {news.map((item, index) => (
                            <div key={item.id} className="col-12 col-sm-6 col-lg-4">
                                <Link to={`/novidade/${item.url}`}>
                                    <Card>
                                        <img src={Base} className="card-img-top" style={{backgroundImage: `url(${lang==='pt' ? item.image : item.image_en})`}} />
                                        <Card.Body>
                                            <Card.Title>{lang==='pt' ? item.title : item.title_en}</Card.Title>
                                            <Card.Text>
                                                <LuCalendarDays />
                                                <span>{lang==='pt' ? item.date : item.date_en}</span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>))}
                        </div>
                    </div>}
                </div>:
                <div className="full mt-30 mb-40 form">
                    {news!='' &&
                    <div className="container">
                        <div className="row gh-4 gv-4 news">
                            <div className="col-12 col-sm-10 col-lg-10 m-auto">
                                <h3 className="title_b text-center mb-20">
                                    {lang==='pt' ? news.title : news.title_en}
                                </h3>
                                <div className="date_b mb-30">
                                    <LuCalendarDays />
                                    <span>{lang==='pt' ? news.date : news.date_en}</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-10 col-lg-8 m-auto">
                                {items.map((item, index) => (
                                <div key={`content-${index}`}>
                                    {item.type==='image' &&
                                    <div className="mb-20">
                                        <img src={item.image} className="news-image" />
                                    </div>}
                                    {item.type==='text' &&
                                    <div className="news-text mb-20" dangerouslySetInnerHTML={{__html: lang==='pt' ? item.text : item.text_en}} />}
                                    {item.type==='video' &&
                                    <div className="mb-20">
                                        <div className="video-container">
                                            {
                                            item.video_type==='vimeo' ?
                                            <iframe src={`https://player.vimeo.com/video/${item.video}?title=0&byline=0&portrait=0`} frameBorder="0" allowFullScreen></iframe>:
                                            <iframe src={`https://www.youtube.com/embed/${item.video}?rel=0&showinfo=0`} frameBorder="0" allowFullScreen></iframe>
                                            }
                                        </div>
                                    </div>}
                                </div>))}
                            </div>
                        </div>
                    </div>}
                </div>}
            </>}
            <Footer />
        </div>
    );
}
